@import "src/scss/_colors.scss", "src/scss/_mixins.scss";

.brick-filter {
  @include flex($gap: 10px);

  .brick {
    padding: 4px 10px;
    border: 1px solid $theme-disable;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    transition: all 0.3s ease-in-out;
    font-weight: 600;
    text-wrap: nowrap;
    width: fit-content;

    &:hover {
      background: $theme-gray;
    }

    &.selected {
      background: $theme-purple-bg;
      color: $theme-purple;
      border-color: $theme-purple;
    }
  }
}
