@import 'src/scss/_colors.scss', 'src/scss/_mixins.scss';

.modal-title {
  font-size: 1.25rem !important;
}

.settings-modal-body {
  @include flex(
    $flex-direction: column,
    $justify-content: flex-start,
    $gap: 16px,
    $align-items: flex-start
  );

  .disabled {
    pointer-events: none;
    opacity: 0.5;
    user-select: none;
  }

  .not-organizer-message {
    background-color: $theme-light-purple;
    border: 1px solid $theme-light-purple-border;
    border-radius: 8px;
    padding: 16px;
    @include flex(
      $flex-direction: column,
      $justify-content: flex-start,
      $gap: 14px,
      $align-items: flex-start
    );

    .info-symbol {
      @include flex($flex-direction: row, $justify-content: flex-start, $align-items: flex-start);
      width: 100%;
      span {
        width: 20px;
        height: 20px;
        display: inline-block;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .info-message {
      @include flex(
        $flex-direction: column,
        $justify-content: flex-start,
        $align-items: flex-start,
        $gap: 4px
      );
      color: $theme-purple;
      line-height: 20px;

      .first {
        font-size: 15px;
        font-weight: 500;
      }
      .second {
        font-size: 13px;
        font-weight: 400;
      }
    }

    .info-message {
      @include flex(
        $flex-direction: column,
        $justify-content: flex-start,
        $align-items: flex-start,
        $gap: 6px
      );
    }
  }

  .auto-share-toggle {
    @include flex(
      $flex-direction: row,
      $justify-content: flex-start,
      $gap: 8px,
      $align-items: center
    );
    
    span {
      font-size: 14px;
    }

    $toggle-width: 40px;
    $toggle-height: calc($toggle-width * 0.6);

    .toggle {
      width: $toggle-width;
      height: $toggle-height;
      position: relative;
      border-radius: 25px;
      cursor: pointer;
      transition: background 0.3s ease-in-out;
      background-color: white;
      display: flex;
      align-items: center;
      padding: 2px;
      background-color: $theme-disable;

      .toggle-slider {
        background: white;
        border-radius: 50%;
        transition: transform 0.3s ease-in-out;
        width: calc($toggle-height - 7px);
        height: calc($toggle-height - 7px);
        transform: translateX(-1px);
        @include flex;
      }

      input {
        display: none;
      }

      &.active {
        background-color: $theme-purple;

        .toggle-slider {
          background: white;
          transform: translateX(calc($toggle-width - $toggle-height));
        }
      }
    }
  }

  .summary-options {
    @include flex(
      $flex-direction: column,
      $justify-content: flex-start,
      $gap: 6px,
      $align-items: flex-start
    );

    p {
      font-weight: 600;
      font-size: 13px;
    }
  }
}

.save-button {
  background-color: $theme-purple !important;
  color: white !important;
  padding: 8px 16px !important;
  border-radius: 6px !important;
  font-size: 14px !important;
}
