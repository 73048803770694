@import "src/scss/_colors.scss", "src/scss/_mixins.scss";


.dropdown {
  position: relative;
  display: inline-block;
  font-size: 1rem;
  cursor: pointer;
  box-sizing: border-box;
  margin-right: 15px;
  width: fit-content !important;

  .dropdown-title::after {
    content: "›";
    position: absolute;
    font-weight: bold;
    transform: rotate(90deg);
    right: -15px;
  }

  .dropdown-content {
    display: none;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    right: -10px;
    color: $primary;
    background-color: $lightest-gray;
    width: fit-content;
    min-width: 70px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    padding: 10px 12px;
    z-index: 1;
    font-size: 0.8rem;

    a {
      cursor: pointer;
      white-space: nowrap;
      border-bottom: 1px solid $secondary;
      padding: 4px 6px;
      box-sizing: border-box;
      width: 100%;
      text-align: center;
    }

    a:hover {
      background-color: $light-gray;
    }

    a:last-child {
      border-bottom: none;
    }
  }

  &:hover .dropdown-content {
    display: flex;
  }
}

