@import "src/scss/_colors.scss", "src/scss/_mixins.scss";


.toggle {
  position: relative;
  border-radius: 25px;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
  display: flex;
  align-items: center;
  padding: 2px;
  border: 2px solid $theme-disable;

  .toggle-slider {
    background: $theme-disable;
    border-radius: 50%;
    transition: transform 0.3s ease-in-out;
    @include flex;

    img {
      width: 72%;
    }
  }

  input {
    display: none;
  }

  &.active {
    border: 2px solid $theme-purple;

    .toggle-slider {
      background: $theme-purple;
    }
  }
}