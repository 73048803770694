@import 'src/scss/_colors.scss', 'src/scss/_mixins.scss';

.reminders {
  padding: 20px 0;
  box-sizing: border-box;

  .reminder-section {
    @include flex(
            $flex-direction: column,
            $justify-content: flex-start,
            $align-items: flex-start,
            $gap: 10px
    );
    border: 1px solid $light-gray;
    padding: 16px 20px;
    height: 60vh;
    overflow: auto;
    box-sizing: border-box;
    border-radius: 12px;

    ol {
      margin: 0;
      padding-left: 1rem;

      .reminder {
        margin-bottom: 4px;
      }
    }
  }
}
