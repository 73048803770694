@import "src/scss/_colors.scss", "src/scss/_mixins.scss";

.playback-speed-control {
  color: $primary;

  .playback-speed-dropdown {
    padding: 5px 10px;
    background-color: $light-gray;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    letter-spacing: 0.75px;
    font-size: 14px;
    appearance: none;
    -webkit-appearance: none;
    text-align: center;
    outline: none;

    &:hover {
      background-color: $light-text;
    }
  }
}
