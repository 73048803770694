$primary:       #3c3c3c;
$secondary:     #626262;
$light-text:    #929292;
$box-gray: #f7f7f7;
$light-gray:    #dedede;
$lightest-gray: #f7f7f7;
$theme-light: #1ff6dc;
$theme-dark: #928ed9;
$theme-green-bg: #F0FDF9;
$theme-green: #125D56;
$theme-purple-bg: #F4F3FF;
$theme-purple: #6938EF;
$theme-font: #202939;
$theme-text: #777777;
$theme-disable: #9AA4B2;
$theme-danger: #D92D20;
$theme-light-purple-border: #BDB4FE;
$theme-light-purple: #FAFAFF;
$theme-gray: #CDD5DF;
$theme-gray-bg: #FCFCFD;
$theme-icon: #697586;
