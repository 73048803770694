@import "src/scss/_colors.scss", "src/scss/_mixins.scss";


.mom {
  padding: 20px 0;
  box-sizing: border-box;

  .mom-sections {
    @include flex($flex-direction: column, $justify-content: flex-start, $align-items: flex-start, $gap: 20px);
    height: 60vh;
    overflow: auto;
    box-sizing: border-box;

    .mom-section {
      @include flex($flex-direction: column, $justify-content: flex-start, $align-items: flex-start, $gap: 5px);

      .section-head {
        @include flex($justify-content: space-between);
        cursor: pointer;


        .section-title {
          flex: 1;

          h5 {
            font-size: 1.1rem;
            font-weight: 500;
          }
        }

        .section-controls {
          flex: 0;
        }
      }

      .section-body {
        font-size: 0.8rem;
        border: none;
        border-radius: 10px;
        padding: 0;
        box-sizing: border-box;
        background-color: $theme-gray-bg;
        height: 0;
        overflow: hidden;
        transition: opacity 0.4s ease-in-out;
        opacity: 0;

        &.open {
          height: auto;
          padding: 8px 12px;
          border: 1px solid $theme-gray;
          opacity: 1;
        }

        .mom-head {
          font-weight: 600;
          font-size: 0.9rem;
          margin-bottom: 2px;
          display: block;
        }

        .mom-sub-text:not(:last-child) {
          margin-bottom: 10px;
        }

        .mom-sub-text {

          .mom-head {

          }

          .mom-sub-text {
            margin-bottom: 0;
          }

        }

      }
    }
  }
}

