.transcription-file {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 6px;

  .file-link {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}