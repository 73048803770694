@import "src/scss/_colors.scss", "src/scss/_mixins.scss";

.tabs-container {
  width: 100%;
  margin: 0 auto;

  .tabs-bar {
    position: relative;
    border-bottom: 2px solid $theme-gray;

    .tabs {
      display: flex;
    }

    .tab {
      text-align: center;
      padding: 10px 2px;
      cursor: pointer;
      font-size: 1rem;
      color: $theme-font;
      margin-right: 16px;
      margin-bottom: -2px;
      font-weight: 500;
    }

    .tab.active {
      color: $theme-purple;
      border-bottom: 2px solid $theme-purple;
    }
  }
}
