@import "src/scss/_colors.scss", "src/scss/_mixins.scss";

.meetings {
  @include flex($justify-content: flex-start, $align-items: flex-start, $gap: 0);
  flex: 1;

  .content {
    flex: 1;
    height: calc(100vh - 63px);
    @include flex($flex-direction: column, $justify-content: flex-start, $align-items: flex-start, $gap: 10px);
    padding: 20px 30px;

    .no-transcription {
      width: 100%;
      height: 100%;
      font-size: 16px;
      @include flex;
    }

    .content-header {
      @include flex($justify-content: space-between);

      .title {
        font-size: 1.8rem;
        font-weight: 500;
        flex: 1;
      }

      .controls {
        @include flex($justify-content: flex-end);
        flex: 1;

        button {
          font-size: 0.9rem;
          font-weight: 500;
          border: none;
          border-radius: 6px;
          padding: 8px 16px;
          background-color: transparent;
          color: $theme-font;
          box-sizing: border-box;
          text-wrap: nowrap;
        }

        .simple-btn {
          font-weight: 700;
          border: 1px solid $theme-gray;
        }

        .action-btn {
          background-color: $theme-purple;
          color: white;
        }

        .danger-btn {
          border: 1px solid $theme-danger;
          color: $theme-danger;
        }

      }

    }

    .meta-data {
      @include flex($justify-content: flex-start, $gap: 20px);
      color: $theme-purple;

      .type {
        font-size: 0.75rem;
        font-weight: 500;
        border: 1px solid $theme-light-purple-border;
        background-color: $theme-purple-bg;
        padding: 5px 10px;
        border-radius: 20px;
      }

      .rating {
        width: fit-content;
      }
    }
  }

}

.standard-btn {
  background-color: $theme-purple !important;
  color: white !important;
}
