@import 'src/scss/_colors.scss', 'src/scss/_mixins.scss';

.settings-dropdown {
  position: relative;
  font-size: 14px;
  cursor: pointer;
  box-sizing: border-box;
  width: 100% !important;
  border: 1px solid #cdd5df;
  border-radius: 6px;
  height: 40px;

  .selected-option {
    position: relative;
    padding: 8px 10px;
    @include flex($flex-direction: row, $justify-content: flex-start, $align-items: flex-start);

    span {
      width: 95%;
      margin-right: 6px;
    }

    .chevron {
      width: 5%;
      margin-right: 0;

      img {
        width: 50%;
        height: 50%;
        object-fit: contain;
        transform-origin: center;
      }
    }
  }

  .select-options {
    display: none;
    border-radius: 8px;
    flex-direction: column;
    position: absolute;
    top: 44px;
    color: #0d121c;
    background-color: white;
    width: 100% !important;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    padding: 2px;
    z-index: 1;
    border: 1px solid #e3e8ef;

    &.show-dropdown {
      display: flex !important;
    }

    span {
      cursor: pointer;
      @include flex($flex-direction: row, $justify-content: flex-start, $align-items: center);
      white-space: nowrap;
      border-radius: 4px;
      padding: 6px 12px;
      box-sizing: border-box;
      width: 100%;
      height: 40px;
      text-align: left;
      text-decoration: none;
      font-size: 13px;
      line-height: 24px;
      font-weight: 400;
      transition: background-color 0.2s ease;

      &:hover {
        background-color: $theme-purple-bg;
        color: $theme-purple;
      }
    }

    span.active {
      background-color: $theme-purple-bg;
      color: $theme-purple;
    }
  }

  &:hover .dropdown-content {
    display: flex;
  }
}
