@import "src/scss/_colors.scss", "src/scss/_mixins.scss";

.modal-content {
  border-radius: 0;

  .modal-header {
    border-bottom: none;

    .modal-title {
      font-size: 1.5rem;
      font-weight: 700;
    }
  }

  .modal-body {
    padding-top: 0;
  }

  .modal-footer {
    border-top: none;
    gap: 10px;

    button {
      font-size: 1rem;
      font-weight: 500;
      border: none;
      border-radius: 10px;
      padding: 8px 16px;
      background-color: transparent;
      color: $theme-font;
    }

    .simple-btn {
      font-weight: 700;
    }

    .action-btn {
      background-color: $theme-font;
      color: white;
    }

    .danger-btn {
      background-color: $theme-danger;
      color: white;
    }
  }
}
