@import "src/scss/_colors.scss", "src/scss/_mixins.scss";
@import url('https://fonts.googleapis.com/css2?family=Noto+Nastaliq+Urdu:wght@400..700&display=swap');

@font-face {
  font-family: "Satoshi Variable";
  src: url("./assets/fonts/Satoshi-Variable.ttf") format("truetype");
  font-weight: 100 900;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi Variable";
  src: url("./assets/fonts/Satoshi-VariableItalic.ttf") format("truetype");
  font-weight: 100 900;
  font-style: italic;
}


@font-face {
  font-family: "EnglishFont";
  src: local("Satoshi Variable");
  //Covers English characters
  unicode-range: U+0000-007F;
}

@font-face {
  font-family: "UrduFont";
  src: local("Noto Nastaliq Urdu");
  //Covers Urdu script
  unicode-range: U+0600-06FF, U+0750-077F, U+FB50-FDFF, U+FE70-FEFF;
}

* {
  padding: 0;
  margin: 0;
}

body {
  font-family: "Satoshi Variable", "Kumbh Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-size: 14px;
  font-style: normal;
  color: $primary;
}

input, button, select {
  font-family: "Satoshi Variable", "Kumbh Sans", sans-serif !important;
}

h1, h2, h3, h4 {
  font-weight: 500;
  color: $primary;
}

h1 {
  font-size: 1.25rem;
}

p {
  font-size: 12px;
  margin: 0;
  padding: 0;
}

label {
  cursor: pointer;
}

.padding-25 {
  padding: 25px;
}

.border-bottom {
  border-bottom: 1px solid $light-gray;
}

a {
  text-decoration: none;
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.custom-container {
  max-width: 1080px;
  margin: 0 auto;
}

.help-text {
  font-size: 0.75rem;
  color: $light-text;
}

.error {
  margin: 5px 0 0;
  color: red;
  font-size: 12px;
}

.mt10 {
  margin-bottom: 10px;
}

ul {
  margin: 0;
  padding: 0;
}

.customPrimaryBtn {
  background-color: #dedede;
  color: #3c3c3c;
  float: right;
  font-size: 0.75rem;
  line-height: normal;
  padding: 8px 18px;
  text-wrap: nowrap;
  border: none;
}
