@import "src/scss/_colors.scss", "src/scss/_mixins.scss";

.landing {
  .custom-container {
    padding: 50px 30px;
    box-sizing: border-box;
    @include flex($justify-content: space-between, $align-items: center);

    .hero-img {
      max-width: 50%;
      padding-left: 30px;

      img {
        max-width: 100%;
      }
    }

    .texts {

      .header {

        h1 {
          font-size: 36px;
          font-weight: 700;
          color: $theme-font;
          padding: 0;

          span {
            color: $theme-purple;
          }
        }
      }

      .sub-points {
        margin: 30px 0 0 0;
        @include flex($flex-direction: column, $align-items: flex-start);

        img {
          margin: -5px 10px 0 0;
          height: 20px;
        }

        span {
          color: $theme-text;
          font-size: 14px;
          line-height: 20px;
        }
      }

      .upload-section {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 40px 0 20px;

        .upload-btn {
          display: block;
          color: whitesmoke;
          background-color: red;
          width: 60%;
          border-radius: 10px;
          padding: 10px;
          font-size: 16px;
          font-weight: bold;
          text-transform: uppercase;
        }
      }
    }


  }
}
