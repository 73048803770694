@import "src/scss/_colors.scss", "src/scss/_mixins.scss";

.transcription-section {
  @include flex($flex-direction: column, $justify-content: flex-start, $align-items: flex-start, $gap: 20px);
  border: 1px solid $light-gray;
  padding: 16px 20px;
  height: 49vh;
  overflow: auto;
  box-sizing: border-box;
  border-radius: 12px;
}
