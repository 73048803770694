@import "src/scss/_colors.scss", "src/scss/_mixins.scss";

.rating-modal {
  border-radius: 0;

  .modal-dialog {
    justify-content: center;
  }

  .modal-content {
    max-width: 400px;
    padding: 5px;
  }

  .modal-header {
    border-bottom: none;
  }

  .modal-body {
    @include flex($flex-direction: column, $gap: 0);
    text-align: center;
    padding-top: 0;

    img {
      width: 100%;
      max-width: 200px;
      height: auto;
    }

    .rating {
      padding: 40px 0 20px;
    }

    .msg {
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 30px;
    }

    .feedback {
      font-size: 0.9rem;

      a {
        color: $theme-purple;
        text-decoration: underline;
      }
    }

  }
}
