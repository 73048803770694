@import "src/scss/_colors.scss", "src/scss/_mixins.scss";

.transcription-segment {
  @include flex($flex-direction: column, $justify-content: flex-start, $align-items: flex-start, $gap: 4px);
  list-style: none;
  margin-top: 10px;
  width: 100%;

  li {
    @include flex($justify-content: flex-start, $align-items: flex-start, $gap: 5px);
    width: 100%;
    padding: 4px 6px;
    box-sizing: border-box;
    font-size: 0.8rem;
    line-height: 20px;

    .time {
      font-weight: bold;
    }

    .speaker-name {
      font-weight: bold;
    }

    .text {
      display: block;
      max-width: 100%;
      overflow: hidden;
      text-wrap: nowrap;
      text-overflow: ellipsis;
    }

    .selector {
      margin-top: 3px;
    }

    .speakers {
      width: 100px;
    }

    .time {
      width: 120px;
      text-align: center;
    }

    .transcription {
      padding: 0 5px;
      flex: 1;
      font-family: "EnglishFont", "UrduFont", "Kumbh Sans", sans-serif;
    }

    .speaker {
      color: $light-text;
    }

    &.active {
      font-weight: bold;
      color: $primary;
      background-color: $light-gray;
    }
  }
}