@import 'src/scss/_colors.scss', 'src/scss/_mixins.scss';

.contenteditable {
  border: 1px solid transparent;
}

.add-item {
  @include flex(
    $flex-direction: row,
    $justify-content: flex-start,
    $align-items: center,
    $gap: 6px
  );
  padding-left: 2px;
  cursor: pointer;
}

.subheading-section {
    position: relative;
}

.subheading-section > .remove {
    display: none;
    cursor: pointer;
}

.subheading-section:hover > .remove {
    @include flex($flex-direction: row, $justify-content: center, $align-items: center);
    position: absolute;
    left: -18px;
    top: 14px;
}

.edit-symbol {
  width: 16px;
  height: 16px;
  padding: 1px;
  background-color: $theme-purple;
  border-radius: 50%;
  @include flex($flex-direction: row, $justify-content: center, $align-items: center);

  img {
    width: 75%;
    height: 75%;
    object-fit: contain;
  }
}

.contenteditable:focus {
  outline: none;
  border: 1px solid $theme-purple !important;
}

.contenteditable:hover {
  border: 1px solid $theme-purple !important;
}

.contenteditable:empty:before {
  content: attr(data-placeholder);
  color: #aaa;
  pointer-events: none;
}
