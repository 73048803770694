@import "src/scss/_colors.scss", "src/scss/_mixins.scss";

.pb-dark {
  background-color: #929292 !important;
}

.progress-bar-container {
  width: 100%;
  background-color: #e0e0df;
  border-radius: 25px;
  overflow: hidden;
  position: relative;

  .progress-text {
    float: left;
    width: 100%;
    text-align: center;
    color: #fff;
    font-weight: bold;
    font-size: 0.75rem;
    position: absolute;
  }

  .progress-bar {
    height: 18px;
    background-image: linear-gradient(to right, $theme-light, $theme-dark);
    border-radius: 25px;
    transition: width 0.25s;
  }
}