@import "src/scss/_colors.scss", "src/scss/_mixins.scss";

.sidebar {
  width: 280px !important;
  border-right: 1px solid $light-gray;
  height: calc(100vh - 63px);
  @include flex($flex-direction: column, $justify-content: flex-start, $gap: 0);

  .no-flex {
    flex: unset !important;
  }

  .files {
    border-bottom: 1px solid $light-gray;
    padding: 14px 20px;
    height: fit-content;
    max-height: 20%;

    h2 {
      margin-bottom: 0;
      cursor: pointer;
      padding: 0 0 5px;

      .right-arrow {
        img {
          transition: all .2s;
        }
      }

      &.opened {
        .right-arrow {
          img {
            transition: all .2s;
          }
        }
      }
    }

    ul {
      list-style: none;
      height: 0;
      transition: all .2s;
      overflow: hidden;

      li {
        font-size: 0.75rem;
        margin: 5px 0;
        padding: 4px 6px;
        cursor: pointer;

        &.active {
          background-color: $light-gray;
        }

        a {
          color: $primary;
        }

        b {
          display: inline-block;
          max-width: 120px;
          overflow: hidden;
          text-overflow: ellipsis;
          text-wrap: nowrap;
          font-weight: 500;
          vertical-align: middle;
        }

        img {
          margin-right: 3px;
          vertical-align: middle;
        }

        span {
          display: inline-block;
          color: $light-text;
          vertical-align: bottom;

          &.loader {
            float: right;
            position: relative;

            &:before {
              content: "";
              position: absolute;
              width: 100%;
              height: 8px;
              top: 5px;
              background-color: $light-gray;
            }

            i {
              position: relative;
              display: inline-block;

              &:after {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                height: 8px;
                top: 5px;
                background-color: $light-text;
              }
            }
          }
        }
      }

      &.opened {
        height: 90%;
        overflow: auto;
      }
    }
  }

  .title-section, .meeting-type-section {
    border-bottom: 1px solid $light-gray;
    padding: 14px 20px;
    box-sizing: border-box;
  }

  .meeting-type-section {
    .meeting-options {
      width: 100%;
      height: 28px;
      outline: none;
      border-radius: 0;
      border: 1px solid $light-gray;
      padding: 0 5px;
      line-height: 25px;
      color: $primary;
      font-size: 0.8rem;
    }
  }

  h2 {
    font-size: .8rem;
    font-weight: 600;
    margin-bottom: 15px;

    img {
      margin-left: 3px;
      cursor: pointer;
    }

    span {
      color: $light-text;
      float: right;
      font-weight: 400;
    }
  }

  .speakers {
    flex: 1;
    overflow-y: scroll;
    border-bottom: 1px solid $light-gray;
    padding: 14px 20px;
    box-sizing: border-box;
  }

  .flex {
    @include flex($flex-direction: column, $gap: 8px);

    .speaker {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      width: 100%;

      input, button {
        border-radius: 0;
        border: 1px solid $light-gray;
        padding: 0 5px;
        line-height: 25px;
        color: $primary;
      }

      input {
        outline: none;
        width: 100%;
      }

      button {
        height: 27px;
        cursor: pointer;
        border: none;
        background-color: #dedede;
        padding: 0 8px;
        font-weight: bold;

        &:disabled {
          color: $light-text;
          font-weight: normal;
        }
      }

      &.active {
        border: 2px solid $primary;
      }
    }
  }
}

.sidebar {
  &__filters {
    @include flex($flex-direction: column, $gap: 10px);
    padding: 14px 20px;
    border-bottom: 1px solid $light-gray;

    .search-bar {
      width: 100% !important;
      padding: 0 10px !important;
    }

    .brick-filter {
      justify-content: flex-start;
      overflow-x: scroll;
      scrollbar-width: none;
      gap: 4px;

      .brick {
        font-size: 12px;
        padding: 4px 10px;
      }
    }
  }
}