@import "src/scss/_colors.scss", "src/scss/_mixins.scss";


.upload {
  width: 100%;
  @include flex($flex-direction: column, $justify-content: flex-start, $gap: 40px);
  padding: 40px;
  box-sizing: border-box;

  .section {
    @include flex($flex-direction: column, $justify-content: flex-start, $gap: 15px);
    width: 50%;
    max-width: 478px;
    padding: 30px 40px;
    background-color: $lightest-gray;
    box-sizing: border-box;
    text-align: center;

    @media (max-width: $medium) {
      width: 65%;
    }

    @media (max-width: $small) {
      width: 85%;
    }

    .upload-form {
      width: 100%;
      @include flex($flex-direction: column, $justify-content: flex-start, $gap: 15px);

      input[type="file"] {
        opacity: 0;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
      }

      .file-field {
        width: 100%;
        font-size: 0.8rem;
        height: 36px;
        border: 1px solid $light-gray;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        //cursor: pointer;

        .file-name {
          padding: 8px 14px;
          max-width: 187px;
          text-overflow: ellipsis;
          text-wrap: nowrap;
          overflow: hidden;
        }

        .file-handle {
          width: 100px;
          height: 100%;
          line-height: 36px;
          text-align: center;
        }
      }
    }

    .upload-instruction {
      font-size: 0.875rem;
      font-weight: 500;

      span {
        display: block;
        color: $light-text;
      }
    }

    .supported-types {
    }
  }

  .heading {
    text-align: center;
  }

  .section-1 {
    cursor: pointer;
  }

  .section-2 {

    .upload-form {
      text-align: left;
      align-items: flex-start;

      .file-types {

        .heading {
          font-weight: 500;
          line-height: normal;
          text-align: left;
          padding-bottom: 4px;
        }

        span {
          margin-right: 10px;

          label {
            font-size: .75rem;
          }

          input {
            margin-right: 3px;
            vertical-align: middle;
          }
        }
      }

      .btn-submit {
        border: 0;
        background-color: $light-gray;
        float: right;
        padding: 6px 30px;
        font-size: 0.75rem;
        font-weight: 600;
        cursor: pointer;
        align-self: flex-end;
      }
    }
  }
}

.step-msg {
  font-size: 12px;
  display: block;
  color: green;
}

.current-msg {
  color: $primary;

  &::after {
    display: inline-block;
    animation: dotty steps(1, end) 2s infinite;
    content: '';
    font-size: 24px;
    line-height: 12px;
  }

  @keyframes dotty {
    0% {
      content: '.';
    }
    25% {
      content: '..';
    }
    50% {
      content: '...';
    }
    75% {
      content: '..';
    }
    100% {
      content: '.';
    }
  }
}


