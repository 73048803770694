@import 'src/scss/_colors.scss', 'src/scss/_mixins.scss';

.summary {
  padding: 20px 0;
  box-sizing: border-box;

  .summary-section {
    @include flex(
            $flex-direction: column,
            $justify-content: flex-start,
            $align-items: flex-start,
            $gap: 10px
    );
    border: 1px solid $light-gray;
    padding: 16px 20px;
    height: 60vh;
    overflow: auto;
    box-sizing: border-box;
    border-radius: 12px;

    .add-section {
      font-weight: 700;
      font-size: 14px;
      color: $theme-purple;
      cursor: pointer;
      width: 100%;
      padding: 12px 0px;
      border-bottom: 1px solid $theme-gray;
      margin-bottom: 16px;
    }

    p {
      white-space: pre-wrap;
    }

    .summary-content {
      @include flex($flex-direction: column, $align-items: flex-start, $gap: 4px);
      margin: 6px 0 0 0;
    }

    .summary-head {
      font-weight: bold;
      display: block;
      border: 1px solid transparent;
    }

    .summary-metadata {
      @include flex($justify-content: flex-start);
      font-size: 0.875rem;
      flex-wrap: wrap;

      div {
        width: fit-content;
      }
    }

    .summary-sub-text {
      margin-bottom: 4px;
      border-radius: 3px;
      white-space: pre-wrap;
      padding: 2px;
      border: 1px solid transparent;

      .summary-head {
        margin: 8px 10px 5px 0;
        font-size: 0.8rem;
        padding: 2px;
        border-radius: 3px;
      }
    }
  }

  .buttons {
    margin-top: 15px;
    text-align: center;

    a {
      width: 205px;
      height: 44px;
      font-weight: 600;
      text-align: center;
      display: inline-block;
      margin: 8px;
      line-height: 44px;
      color: $primary;
      border: 1px solid $light-gray;

      &[aria-disabled='true'] {
        background-color: $light-gray;
        color: $light-text;
        pointer-events: none;
      }
    }
  }

  .modified-datetime {
    @include flex($flex-direction: row, $align-items: center, $justify-content: flex-end);
    padding-top: 12px;
  }
}

