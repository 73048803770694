@import 'src/scss/_customVariables';


@mixin flex($flex-direction: row, $justify-content: center, $align-items: center, $gap: 10px) {
  display: flex;
  flex-direction: $flex-direction;
  justify-content: $justify-content;
  align-items: $align-items;
  gap: $gap;

  > div {
    width: 100%;
  }
}
