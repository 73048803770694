@import "src/scss/_colors.scss", "src/scss/_mixins.scss";

.loader-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #0b0b0b;
  display: grid;
  place-items: center;
  z-index: 2;
  opacity: 0.4;

  .loader {
    border: 8px solid white;
    border-top: 8px solid $primary;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

}