.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 600px;
  overflow: auto;

  main {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}
