@import "src/scss/_colors.scss", "src/scss/_mixins.scss";

.search-bar {
  display: flex;
  align-items: center;
  border: 1px solid $theme-disable;
  border-radius: 8px;
  padding: 4px 10px;
  width: 300px !important;
  transition: border 0.3s ease-in-out;

  &:focus-within {
    border-color: $theme-purple;
  }

  .search-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $theme-purple;
    margin-right: 6px;

    img {
      width: 18px;
    }
  }

  input {
    flex: 1;
    border: none;
    outline: none;
    font-size: 0.9rem;
    padding: 4px;
    color: #333;

    &::placeholder {
      color: #aaa;
    }
  }

  .clear-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 12px;
    color: $theme-disable;
    padding: 4px;
    margin-left: 5px;
    transition: color 0.2s;

    &:hover {
      color: $theme-purple;
    }
  }
}
