@import "src/scss/_colors.scss", "src/scss/_mixins.scss";

.audio-player {
  margin: 10px 0 0;

  .rhap_container {
    box-shadow: none !important;

    .rhap_current-time, .rhap_total-time {
      width: 52px;
    }

    .rhap_additional-controls {
      visibility: hidden;
    }

    .rhap_volume-controls {
      gap: 10px;

      .download {
        margin-left: 6px;
      }
    }

    .rhap_main-controls {
      gap: 5px;

      .rhap_main-controls-button {
        font-size: unset;
        margin: 0 !important;
        width: 40px;
        height: 40px;
      }
    }

    .rhap_progress-indicator, .rhap_volume-indicator {
      background-color: $primary;
    }

    .rhap_progress-filled, .rhap_volume-bar {
      background-color: $light-text;
    }

    .rhap_skip-button {
      svg {
        margin-left: -2px;
      }
    }
  }
}
