@import "src/scss/_colors.scss", "src/scss/_mixins.scss";

.meeting-config {
  @include flex($flex-direction: column, $gap: 20px);
  padding: 30px;

  .mc-header {
    @include flex($justify-content: space-between);

    .mc-title {
      flex: 0;

      h1 {
        font-size: 1.5rem;
        font-weight: 700;
        color: $theme-purple;
      }
    }

    .mc-controls {
      @include flex($justify-content: flex-end, $gap: 20px);
      text-align: right;
      flex: 1;

      > div {
        width: fit-content;
      }

      .mc-disable-all {
        @include flex($justify-content: flex-end);
        font-size: 0.9rem;
        font-weight: 400;
      }
    }
  }

  .mc-content {
    height: calc(100vh - 232px);
    overflow-y: scroll;
    //scrollbar-width: none;
    //-ms-overflow-style: none;
  }

  //.my-div::-webkit-scrollbar {
  //  display: none;
  //}
}

.event-group {
  @include flex($flex-direction: column, $gap: 15px);
  margin: 0 0 20px 0;

  .eg-date {
    font-size: 1rem;
    font-weight: 600;
  }

  .eg-events {
    @include flex($flex-direction: column, $gap: 10px);
  }
}

.event {
  @include flex($justify-content: space-between);
  padding: 10px 12px;
  border: 1px solid $theme-font;
  border-radius: 6px;
  color: $theme-font;

  &.faded {
    border: 1px solid $theme-disable;
    color: $theme-disable !important;

  }

  .e-detail {
    text-align: left;
    @include flex($flex-direction: column, $gap: 5px);

    .e-header {
      @include flex($justify-content: flex-start, $gap: 10px);

      .e-name {
        font-size: 1rem;
        font-weight: 500;
      }

      .e-type {
        padding: 8px 10px;
        border-radius: 16px;
        font-size: 0.75rem;
        font-weight: 600;

        &.green {
          color: $theme-green;
          background-color: $theme-green-bg;
        }

        &.purple {
          color: $theme-purple;
          background-color: $theme-purple-bg;
        }

      }
    }

    .e-time {
      @include flex($justify-content: flex-start, $gap: 5px);
      font-size: 0.75rem;
      font-weight: 600;
    }


  }

  .e-controls {
    @include flex($justify-content: flex-end, $gap: 15px);
    text-align: right;
    font-weight: 500;


    .resources {
      @include flex($gap: 5px);
      background: none;
      border: none;
      padding: 10px;
      color: $theme-font;

      img {
        height: 20px;
      }
    }

    .settings-icon {
      width: 20px;
      height: 20px;
      cursor: pointer;

      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }

    .settings-icon.organizer {
      width: 26px;
      height: 26px;
      cursor: pointer;

      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }

    .join {
      @include flex($gap: 10px);
      padding: 8px 16px;
      background: none;
      border: 1px solid $theme-font;
      border-radius: 6px;
      color: $theme-font;

      img {
        width: 22px;
      }
    }
  }
}
